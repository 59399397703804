import React ,{ useContext} from 'react';
import { useRefresh } from 'react-admin';
import {UserContext} from '../auth/UserContext';
import { Chip } from '@mui/material';
import Button from '@mui/material/Button';
import axios from '../axiosConfig';

const CustomUser = ({record})=>{
    if (!record) return <Chip label="Loading..." />;
    return <Chip label={`${record.firstName} ${record.lastName}`} />
}

const CustomRush = ({record, setIsLoading, userTeams})=>{
    const authUser = useContext(UserContext);
    const refresh = useRefresh();
    const indexInTeam = userTeams.indexOf(record.task.job.teamId);
    const isLeader =  (authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 1)? true : false;

    const handleRushTask = async(task) => {
        let confirm = window.confirm("Are you sure to rush this task?");
        if(!confirm) return;
        try{
            setIsLoading(true);
            const data = {rush:true, statusDetail:{push:{userRush:authUser.id}}};
            await axios.put(`${process.env.REACT_APP_API_URL}api/task/${task.id}`,data); 
            if(task.childTaskId){
                await axios.put(`${process.env.REACT_APP_API_URL}api/task/${task.childTaskId}`,data);
            }
            
        } catch(err){
            console.log(err);
        }finally{
            refresh();
            setIsLoading(false);
        }
    }
    if(record.status === 'Completed' || record.status === 'Approved') return;
    
    if(record.task.rush){
        if(record.staffId === authUser.id) return <strong>Need to Work Now</strong>;
        else if(record.staffIds && record.staffIds[1] === authUser.id) return <strong>Need to Work Now</strong>;
        else return;
    } 
    if(record.task.staffId === authUser.id && record.task.childTaskId && !record.task.rush) return <Button onClick={() =>handleRushTask(record.task)} color="primary">Rush</Button>;
    if(!isLeader) return;
    return <Button onClick={() =>handleRushTask(record.task)} color="primary">Rush</Button>
}

export {CustomUser, CustomRush};
import React , {useState} from "react";
import { 
    Edit, 
    useUpdate,
    SimpleForm,
    ReferenceInput,
    required, 
    TextInput, 
    SelectArrayInput,
    SelectInput,
    useNotify, 
    useRedirect
} from "react-admin";
import { Grid } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const EditTeam = (props) =>{
    const [isLoading, setIsLoading] = useState(false); 
    const [update] = useUpdate('team');
    const [countryName, setCountryName] = useState("Viet Nam");
    const countryChoices = [
        {id:"Asia/Bangkok", name : "Viet Nam"},
        {id:"America/Los_Angeles", name : "United States"},
    ];
    const redirect = useRedirect();
    const notify = useNotify();

    const handleSubmit = async (values) => {
        setIsLoading(true);
        const data ={
            name: values.name,
            country: countryName,
            info: values.info,
            googleId: values.googleId
        }
        update('team',{id:values.id,data:data},{
            onSuccess : (response) => {
                notify('Team Updated', 'info', {}, false);
                setIsLoading(false);
                redirect('/team');
            },
            onFailure : (error) => {
                console.error(error); // Xử lý lỗi
            }
        });    
    }

    const handeSelectCountry = (event)=>{
        const selectedCountry = countryChoices.find(choice => choice.id === event.target.value);
        if(selectedCountry){
            setCountryName(selectedCountry.name);
        }
    }

    return (
    <Edit {...props} mutationMode="optimistic">
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <SimpleForm onSubmit={handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <TextInput source="name" validate={[required()]}  label="Name" fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <SelectInput
                        source="info.timeZone"
                        choices={countryChoices}
                        defaultValue = {"Asia/Bangkok"}
                        validate={[required()]}     
                        onChange={handeSelectCountry}    
                        fullWidth       
                    />
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <TextInput  source="googleId" validate={[required()]}   label="Google Drive Id" fullWidth/>                    
                </Grid>
                <Grid item xs={3}>
                    <ReferenceInput label="Managers" source="managers" reference = "user" filter={{ teams: { some: { role: { gt: 2 } } } }}>                
                        <SelectArrayInput optionText="firstName"validate={[required()]} fullWidth disabled/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                    <ReferenceInput source="leaders.edit" reference = "user" filter={{ teams: { some: { role: { gt: 1 } } } }} >
                        <SelectArrayInput optionText="firstName" validate={[required()]} fullWidth disabled/>
                    </ReferenceInput>
                </Grid>
                <Grid item xs={3}>
                    <ReferenceInput source="leaders.thumbnail" reference = "user" filter={{ teams: { some: { role: { gt: 1 } } } }}>
                        <SelectArrayInput optionText="firstName" validate={[required()]} fullWidth disabled/>
                    </ReferenceInput>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <TextInput source="info.dueDays.outline" validate={[required()]}   label="Due Days for Outline" type="number" fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <TextInput source="info.dueDays.script" validate={[required()]}   label="Due Days for Script" type="number" fullWidth/>
                </Grid>   
                <Grid item xs={3}>
                    <TextInput source="info.dueDays.edit" validate={[required()]}   label="Due Days for Edit" type="number" fullWidth/>
                </Grid>
                <Grid item xs={3}>
                    <TextInput source="info.dueDays.thumbnail" validate={[required()]}   label="Due Days for Thumbnail" type="number" fullWidth/>
                </Grid>
            </Grid>      
        </SimpleForm>
    </Edit>
)}

export default EditTeam
import React , {useState, useContext} from "react";
import { 
    Create, 
    useCreate,
    SimpleForm,
    ReferenceInput,
    ArrayInput,
    SimpleFormIterator,
    required, 
    TextInput, 
    DateInput,
    SelectInput,
    useRedirect
} from "react-admin";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { UserContext } from "../auth/UserContext";

const CreateVideo = (props) =>{
    const authUser = useContext(UserContext);
    const [isLoading, setIsLoading] = useState(false); 
    const [team, setTeam] = useState({});
    const [create] = useCreate('video');
    const redirect = useRedirect();

    function extractDriveId(url, type) {
        // Sử dụng Regular Expression để tìm ID từ link Google Drive
        const regex = type === 'file'? /\/d\/([a-zA-Z0-9_-]+)/ : /\/drive\/folders\/([a-zA-Z0-9_-]+)/;
        const match = url.match(regex);
    
        // Kiểm tra xem có match không và trả về ID
        if (match && match[1]) {
            return match[1];
        } else {
            return null; // Trả về null nếu không tìm thấy ID
        }
    }

    const handleSubmit = async (values) => {
        let ideas = [];
        if(!values.extraInfo.ideas) {
            if(!window.confirm('Are you sure you want to create a video without ideas?')) return;

        } else{
            values.extraInfo.ideas.forEach(element => {
                ideas.push({id:extractDriveId(element,'file')});
            });
        }

        let thumbnails = [];
        if(!values.extraInfo.thumbnails) {
            if(!window.confirm('Are you sure you want to create a video without thumbnails?')) return;
        } else {
            values.extraInfo.thumbnails.forEach(element => {
                thumbnails.push({id:extractDriveId(element, 'file')});
            });
        }
        setIsLoading(true);
        const data ={
            name: values.name,
            status: values.publishedDate? 'Posted' : 'Ready to Post',
            extraInfo: {
                videoId: extractDriveId(values.extraInfo.videoId, 'file'),
                ideas: ideas,
                thumbnails: thumbnails
            },
            directory: extractDriveId(values.directory, 'folder'),
            team:{connect: {id: values.teamId}},
            publishedDate: values.publishedDate? values.publishedDate : null,
            channelId: values.channelId? values.channelId : null,
            staffId: values.staffId,
            type: false
        }
        create('video',{data},{
            onSuccess : (response) => {
                setIsLoading(false);
                redirect('/video');
            },
            onFailure : (error) => {
                console.error(error); // Xử lý lỗi
            }
        });    
    }

    return (
    <Create {...props} >
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
        <SimpleForm onSubmit={handleSubmit}>
            <TextInput source="name" validate={[required()]} />
            <ReferenceInput source="teamId" reference="team" filter={{members:{some:{userId:authUser.id, role:3}}}} >
                <SelectInput optionText="name" validate={[required()]} onChange={(e)=>setTeam(e.target.value)}  />
            </ReferenceInput>
            { team>0 && <ReferenceInput label="User Create" source="staffId" reference="user" filter={{teams:{some:{"teamId":team}}, taskTypes: { some: { taskTypeId: 1 } }}}>
                <SelectInput optionText={(record)=>`${record.firstName} ${record.lastName}`} validate={[required()]} />
            </ReferenceInput>}
            { team>0 && <ReferenceInput label="Channel" source="channelId" reference="channel" filter={{teamId:team}}>
                <SelectInput optionText={(record)=>`${record.name}`} />
            </ReferenceInput>}
            <TextInput source="directory" validate={[required()]} label="Location in Google Drive" />
            <TextInput source="extraInfo.videoId"  label="Url of Video in Google Drive" />
            <ArrayInput label="Idea Images" source="extraInfo.ideas" fullWidth>
                <SimpleFormIterator>
                    <TextInput source="" label="Url of Idea in Google Drive" fullWidth />
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput label="Thumbnail Images" source="extraInfo.thumbnails" fullWidth>
                <SimpleFormIterator>
                    <TextInput source="" label="Url of Thumbnail in Google Drive" fullWidth />
                </SimpleFormIterator>
            </ArrayInput>
            <DateInput source="publishedDate"  defaultValue={new Date()} />
        </SimpleForm>
    </Create>
)}

export default CreateVideo

import React, {useEffect, useContext, useState }  from "react";
import {     
    List,
    Datagrid,
    TextField,
    ReferenceField,  
    ReferenceInput,
    FunctionField,
    Filter,
    TextInput,
    SelectInput,
    useRefresh
} from 'react-admin';

import DateFieldWithTimezone from "../../utils/DateFieldWithTimezone";
import axios from "../../axiosConfig";
import { UserContext } from "../../auth/UserContext";
import {IdeaRowStyle} from "../../utils/CustomRowStyle";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import { stringify } from 'query-string';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const ImageRenderer = ({ record }) => {
    if(record.length < 1 || !record[0].id) return <img src="/images/no-photo.jpg"  height="72" alt="not found" />;
    return (
        <div>
            {record.map((item, index) => <a 
                key={index}
                href={`https://drive.google.com/file/d/${item.id}/view`} 
                target="_blank" rel="noreferrer">
                <img src={`https://drive.google.com/thumbnail?id=${item.id}`} alt="img from GG" height="72" loading="lazy"  />
            </a>)}
        </div>
    );
};

const truncateString = (str, maxLength = 30)=> {
    if (str.length > maxLength) {
        return str.substring(0, maxLength) + '...';
    } else {
        return str;
    }
}

const ListIdeas = (props) =>{ 
    const authUser = useContext(UserContext);
    const refresh = useRefresh();
    const [userTeams, setUserTeams] = useState([]);  
    const [isReject, setIsReject] = useState(false);
    const [reasonReject, setReasonReject] = useState(null); 
    const [ideaActive, setIdeaActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false); 
    const [isSelectTask, setIsSelectTask] = useState(false);
    const [choiseTask, setChoiseTask] = useState(false);
    const [task, setTask] = useState([]);
    const [currentIdea, setCurrentIdea] = useState(false);
    const [filter, setFilter] = useState({})

    useEffect( () => {
        const uniqueTeamIds = authUser.teams.filter(item => item.role>=0 ).map(item => item.teamId);
        setUserTeams(uniqueTeamIds);
        setFilter({
            OR: [
                { staffId: authUser.id },
                {teamId : {  in: uniqueTeamIds } }                        
            ]
        });
    }, [authUser]);

    const IdeaFilter = () => {
        const ideaStatus = [
            { id: 'Open', name: 'Open' },
            // { id: 'Approved', name: 'Approved' },
            // { id: 'Rejected', name: 'Rejected' },
            { id: 'Completed', name: 'Completed' },
            { id: 'Used', name: 'Used' },
        ];
        return (
            <Filter >
                <SelectInput label="Status" source="status" choices={ideaStatus} alwaysOn onChange={filterWithStatus} />
                <ReferenceInput  source="staffId" reference="user" alwaysOn filter={{teams:{some:{teamId:{in:userTeams}, role:{gte:0}}}}} sort={{ field: 'firstName', order: 'ASC' }}>
                    <SelectInput label="Creator" optionText="firstName" optionValue="id" />
                </ReferenceInput>
                <TextInput label="Title" source="title"  alwaysOn/>
            </Filter>
        );
    };

    const filterWithStatus = () => {
        setFilter({
            OR: [
                { staffId: authUser.id },
                { teamId : {  in: userTeams } }
            ]
        });
    }

    const handleSelectTask = async (record,type) => {
        setCurrentIdea(record);
        const query = {filter: JSON.stringify({ status: "Open", type: 1, staffId:record.staffId})};
        try {
            setIsLoading(true); 
            const choise2 = record.status==='Completed'?[]: record.taskId?[{id:-1, name:"Remove Current Task"}]:[];
            const response = await axios.get(process.env.REACT_APP_API_URL+'api/task/'+type+'?'+ stringify(query));
            response.data.map((item,index) => (
                choise2.push({id:item.id,name:`${item.job.name} `})
            ));
            setChoiseTask(choise2);
            setIsSelectTask(true);
        } catch (error) {
            console.log(error);
        } finally {
            await refresh(); 
            setIsLoading(false);
        }
    };

    const handleAction = async (id, status) => {
        if(!window.confirm(`Are you sure you want to ${status} this idea?`)) return;
        setIsLoading(true); 
        try{            
            const messages = reasonReject? {
                reason:reasonReject,
                userReject:authUser.id,
            }: {};
            await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/idea/${id}`,  {status: status, info:{messages:messages}});
        }
        catch(err){
            console.log(err);
        } finally {
            await refresh();
            setIsLoading(false);
        }
    };

    const handleDeleteIdea = async (id) => {
        if(!window.confirm("Are you sure you want to delete this idea?")) return;
        setIsLoading(true); 
        try{
            await axios.delete(`${process.env.REACT_APP_API_URL}api/workspace/idea/${id}`);
        }
        catch(err){
            console.log(err);
        } finally {
            await refresh();
            setIsLoading(false);
        }
    }

    const handleSetTask = async () => {
        if(!task) return;
        setIsLoading(true);
        try{
            if(currentIdea.status === "Completed"){
                await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/idea/joinJob/${currentIdea.id}`,  {taskId: task});
            }else{
                await axios.put(`${process.env.REACT_APP_API_URL}api/workspace/idea/${currentIdea.id}`,  {taskId: task===-1?null:task});
            }
            setIsSelectTask(false);
        }
        catch(err){
            console.log(err);
        } finally {
            setTask(false);
            setCurrentIdea(false);
            refresh();
            setIsLoading(false);            
        }
    }

    return ( 
        <List {...props} filter={filter} exporter={false}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}><CircularProgress color="inherit" /></Backdrop>
            <Dialog
                open={isSelectTask}
                onClose={()=>setIsSelectTask(false)}
                scroll="paper"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Select Task</DialogTitle>
                <DialogContent>
                    <span>Task:   </span>
                    <select onChange={(e) => setTask(parseInt(e.target.value))}>
                        <option value="0">Select Task</option> {choiseTask && choiseTask.map((item,index) => (<option value={item.id} key={index}>{item.name}</option>))} 
                    </select>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>handleSetTask()} color="primary">Ok </Button>
                    <Button onClick={()=>setIsSelectTask(false)} color="primary">Cancel </Button>
                </DialogActions>
            </Dialog> 
            <Dialog  //Dialog for reject idea
                open={isReject}
                onClose={()=>setIsReject(false)}
                // maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>Reason for Reject: </DialogTitle>
                <DialogContent>
                    <textarea  
                        fullWidth  
                        style={{ width: '99%', minHeight: '8em' }}
                        value={reasonReject}
                        onChange={(e) => setReasonReject(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>{handleAction(ideaActive,"Rejected"); setIsReject(false);}} color="primary">Reject </Button>
                    <Button onClick={()=>setIsReject(false)} color="primary">Cancel </Button>
                </DialogActions>
            </Dialog>
            <IdeaFilter />
            <Datagrid rowStyle={IdeaRowStyle(authUser, userTeams)}  bulkActionButtons={false}  >
                <FunctionField label="Image" render={(record) => (
                    <ImageRenderer record={record.images} />
                )}/>
                <TextField source="title"/>
                <DateFieldWithTimezone source="updateAt" />
                <TextField source="description" emptyText="No Description" />
                <FunctionField label="urls" render={(record) =>{
                    if(!record.links) return <span>No Url</span>
                    return record.links.map((link, index)=> (<li key={index}><a target="_blank" rel="noreferrer" href={link} >{truncateString(link,30)}</a></li>))
                }}/>
                <ReferenceField source="staffId" reference="User" label="Creator">
                    <FunctionField label="User" render={(record) => `${record.firstName} ${record.lastName}`}  />
                </ReferenceField>    
                <TextField source="status"/>
                <FunctionField label="Actions" render={(record) => {                    
                    switch(record.status){
                        case 'Submitted': return <span>Completed</span>
                        case 'Completed': {
                            if(record.staffId === authUser.id && (!record.info || !record.info.taskId)){
                                return <Button variant="outlined" color="primary" onClick={() =>handleSelectTask(record,'joinJob')} >Join Job</Button> 
                            }
                            else return <span>Completed</span>
                        }
                        case 'Approved': {
                            if(record.staffId === authUser.id){
                                return (<ButtonGroup>
                                    <Button variant="outlined" color="primary" onClick={() =>handleSelectTask(record,'setTask')} >Set Task</Button> 
                                    {record.taskId && <Button variant="outlined" color="primary" onClick={() => handleAction(record.id, "Submitted")}>Submit</Button>}
                                </ButtonGroup>)
                            }
                            else return <span>Approved</span>
                        }
                        case 'Rejected': return <span>Rejected</span>
                        case 'Open': {
                            const indexInTeam = userTeams.indexOf(record.teamId);
                            if(authUser.teams[indexInTeam] && authUser.teams[indexInTeam].role > 1){
                                return <ButtonGroup>
                                    <Button variant="outlined" color="primary" onClick={() => handleAction(record.id, "Approved")}>Approve</Button>
                                    <Button variant="outlined" color="primary" onClick={() => {setIsReject(true);setIdeaActive(record.id)}}>Reject</Button>
                                </ButtonGroup>
                            }
                            return <span>Waiting for Approval</span>
                        }
                        default: return <span>Server Error</span>
                    }
                }}/>
                <FunctionField label="" render={(record) => {
                    if(record.status === "Approved" && record.staffId === authUser.id) {
                        return <DeleteIcon style={{cursor: 'pointer'}} onClick={() => handleDeleteIdea(record.id)} />
                    }
                    return null;
                }}/>
            </Datagrid>
        </List>
    )
};

export default ListIdeas;
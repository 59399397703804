import axios from "../axiosConfig";

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
}

const getGoogeAuthUrl = async () => {
    let response = await axios.get(`${process.env.REACT_APP_API_URL}api/auth/google`,{
        headers : {staffId : 1}
    });
    return response.data.authUrl;
}

const authProvider = {   
    login: ({username, password }) =>  {
        const request = new Request(process.env.REACT_APP_API_URL + 'api/login', {
            method: 'POST',
            // body: JSON.stringify({ username:username, password  }),
            body: JSON.stringify({ username:username, password : encodeURIComponent(password) }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(async (auth) => {         
                // console.log(auth.info);       
                document.cookie = `auth=${JSON.stringify(auth)};path=/`;
                if(auth.info === false){
                    let url = await getGoogeAuthUrl();
                    window.location.href = url;
                }
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },

    checkAuth: () => {    
        const auth = getCookie('auth');
        return auth ? Promise.resolve() : Promise.reject({ message: false });
    },

    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },

    logout: () => {
        document.cookie = "auth=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        return Promise.resolve();
    },

    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },

     // Phương thức lấy thông tin định danh của người dùng l
     getIdentity: () => {
        const auth = getCookie('auth');
        return auth ? JSON.parse(decodeURIComponent(auth)) : Promise.reject();
    }
    // ...
};

export default authProvider;